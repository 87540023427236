import {
  FC,
  useCallback,
  useMemo,
  useState,
  ReactNode,
} from 'react'
import Drawer from 'react-modern-drawer'
import {
  DEFAULT_DATA,
  INavigationContext,
  Provider
} from './NavigationContext'
import NavigationContent from './NavigationContent'
import DrawerCloseButton from './DrawerCloseButton'
import Styles from './NavigationDrawerProvider.module.sass'

interface NavigationDrawerProviderProps {
  children?: ReactNode | null,
}

const NavigationDrawerProvider: FC<NavigationDrawerProviderProps> = ({
  children,
}) => {
  const [drawerOpen, setVDrawerOpen] = useState(false)

  const setDrawerOpen = useCallback((value: boolean) => {
    setVDrawerOpen(value)
    document?.querySelector('body')?.classList.add('drawerOpen')
  }, [])

  const toggleDrawerOpen = useCallback(() => {
    setVDrawerOpen(!drawerOpen)
    if (drawerOpen) {
      document?.querySelector('body')?.classList.remove('drawerOpen')
    } else {
      document?.querySelector('body')?.classList.add('drawerOpen')
    }
  }, [drawerOpen])

  const contextData = useMemo<INavigationContext>(() => ({
    ...DEFAULT_DATA,
    drawerOpen,
    setDrawerOpen,
    toggleDrawerOpen,
  }), [drawerOpen, setDrawerOpen, toggleDrawerOpen])

  return (
    <Provider value={contextData}>
      {children}
      <Drawer
        duration={200}
        open={drawerOpen}
        direction="right"
        idSuffix="fcdrawer"
        onClose={toggleDrawerOpen}
      >
        <DrawerCloseButton onClick={toggleDrawerOpen} />
        <div className={Styles.wrapper}>
          <NavigationContent drawer />
        </div>
      </Drawer>
    </Provider>
  )
}

export default NavigationDrawerProvider
