import { FC, ReactNode } from 'react'
import Link, { LinkProps } from 'next/link'
import { isPresent } from 'utils'
import Styles from './Button.module.sass'

interface ButtonProps {
  icon?: ReactNode | null,
  label?: ReactNode | string | null,
  text?: ReactNode | string | null,
  href?: LinkProps['href'],
  onClick?: () => void,
}

const Button: FC<ButtonProps> = ({
  href = null,
  onClick = () => undefined,
  ...restProps
}) => href && isPresent(href) ? (
  <Link href={href}>
    <a className={Styles.button}>
      <ButtonContent {...restProps} />
    </a>
  </Link>
) : (
  <button
    className={Styles.button}
    onClick={(_e) => onClick()}
  >
    <ButtonContent {...restProps} />
  </button>
)

const ButtonContent = ({
  icon = null,
  label = null,
  text = null,
}: {
  icon?: ReactNode | null,
  label?: ReactNode | string | null,
  text?: ReactNode | string | null,
}) => (
  <>
    <div className={Styles.icon}>
      {icon}
    </div>
    <p className={Styles.title}>
      {label}
    </p>
    <p className={Styles.text}>
      {text}
    </p>
  </>
)

export default Button
