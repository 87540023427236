import { FC, useMemo } from 'react'
import { FaXingSquare } from 'react-icons/fa'
import { HiChevronDoubleRight } from 'react-icons/hi'
import {
  RiInstagramFill,
  RiFacebookBoxFill,
} from 'react-icons/ri'
import Link from 'next/link'
import Image from 'next/image'
import { Col, Container, Row } from 'atoms'
import YourXpertLogo from 'assets/images/yourXpert_logo_rgb.png'
import Styles from './Footer.module.sass'

const colNavProps = {
  className: Styles.colNavigation,
  xs: 12,
  md: 6,
  lg: 3,
}

const Footer: FC<unknown> = () => {
  const year = useMemo(() => (new Date()).getFullYear(), [])

  return (
    <footer>
      <div className={Styles.sectionTop}>
        <Container>
          <Row>
            <Col {...colNavProps}>
              <div className='headline-container'>
                <h3><HiChevronDoubleRight /> FrauenCoach</h3>
              </div>
              <ul>
                <li>
                  <Link href="/">
                    Startseite
                  </Link>
                </li>
                <li>
                  <Link href="/ihr-coach">
                    Ihr Coach
                  </Link>
                </li>
                <li>
                  <Link href={`/angebot-privatpersonen/beratung-und-therapie-hpg-berlin-mitte`}>
                    Praxis HPG
                  </Link>
                </li>
              </ul>
            </Col>
            <Col {...colNavProps}>
              <div className='headline-container'>
                <h3><HiChevronDoubleRight /> Angebote</h3>
              </div>
              <ul>
                <li>
                  <Link href="/angebot-privatpersonen">
                    Für Privatpersonen
                  </Link>
                </li>
                <li>
                  <Link href="/angebot-firmen">
                    Für Firmen
                  </Link>
                </li>
                <li>
                  <Link href="/seminare-workshops">
                    Seminare &amp; Workshops
                  </Link>
                </li>
              </ul>
            </Col>
            <Col {...colNavProps}>
              <div className='headline-container'>
                <h3><HiChevronDoubleRight /> Service</h3>
              </div>
              <ul>
                <li>
                  <Link href="/kontakt">
                    Kontakt
                  </Link>
                </li>
                <li>
                  <Link href="/blog">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link href="/angebot-privatpersonen/angebote-fuer-senioren">
                    Seniorinnen
                  </Link>
                </li>
              </ul>
            </Col>
            <Col>
              <div className={Styles.externalSocialMedia}>
                <a
                  target="_blank"
                  title="FrauenCoach bei Instagram"
                  rel="noreferrer"
                  href="https://instagram.com/frauencoach.jetzt"
                  className="instagram"
                >
                  <RiInstagramFill />
                </a>
                <a
                  target="_blank"
                  title="FrauenCoach bei Facebook"
                  rel="noreferrer"
                  href="https://www.facebook.com/FrauenCoach.jetzt"
                  className="facebook"
                >
                  <RiFacebookBoxFill />
                </a>
                <a
                  target="_blank"
                  title="FrauenCoach bei Xing"
                  rel="noreferrer"
                  href="https://www.xing.com/profile/Cordelia_Schmidt"
                  className="xing"
                >
                  <FaXingSquare />
                </a>
              </div>
              <div className={Styles.externalYourXpert}>
                <a
                  target="_blank"
                  title="FrauenCoach bei YourXpert"
                  rel="noreferrer"
                  href="https://www.yourxpert.de/xpert/frauen-coach/cordelia.schmidt"
                >
                  <Image
                    src={YourXpertLogo}
                    alt="FrauenCoach bei YourXpert"
                    width={121}
                    height={25}
                  />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={Styles.sectionBottom}>
        <Container>
          <Row>
            <Col>
              Copyright 2014-{year} © Cordelia Schmidt
            </Col>
            <Col>
              <a href="https://hillig.digital" title="hillig.digital">
                Webdesign &amp; Entwicklung by hillig.digital
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
