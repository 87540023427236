import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import Link from 'next/link'
import { Container } from 'atoms'
import { useScroll, useWindowDimensions } from 'services/layout'
import { NavigationContent } from 'services/MainNavigation'
import MenuButton from './MenuButton'
import useHeaderSize from './useHeaderSize'
import Styles from './Header.module.sass'

const Header: FC<unknown> = () => {
  const scroll = useScroll()
  const headerRef = useRef<HTMLElement>(null)
  const { setHeaderHeight } = useHeaderSize()
  const { width: windowWidth } = useWindowDimensions()

  const headerClasses = useMemo(() => [
    Styles.header,
    scroll?.y > 10 ? Styles.headerScrolled : null
  ].join(' '), [scroll?.y])

  const updateHeight = useCallback(() => {
    const height = headerRef?.current?.clientHeight
    setHeaderHeight(parseInt(`${height || ''}`) || 0)
  }, [setHeaderHeight])

  useEffect(() => {
    updateHeight()

    setTimeout(updateHeight, 100)
  }, [updateHeight, windowWidth])

  return (
    <header id="header" className={headerClasses} ref={headerRef}>
      <Container>
        <div className={Styles.top}>
          <Link href='/'>
            <a className={Styles.logo}>
              &nbsp;
            </a>
          </Link>
          <MenuButton />
        </div>
        <nav className={Styles.bottom}>
          <NavigationContent />
        </nav>
      </Container>
    </header>
  )
}

export default Header
