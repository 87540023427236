import { FC } from 'react'
import { Card } from 'atoms'
import { RiContactsFill } from 'react-icons/ri'
import { FaPhoneAlt } from 'react-icons/fa'
import { IoIosHome } from 'react-icons/io'
import { useShowContactModal } from 'services/ContactModal'
import Button from './Button'
import Styles from './ContactCard.module.sass'

export interface ContactCardProps {
  className?: string;
}

const ContactCard: FC<ContactCardProps> = ({
  className,
}) => {
  const showContactModal = useShowContactModal()

  return (
    <Card className={`${Styles.card} ${className || ''}`}>
      <Button
        label="Kontakt"
        icon={<RiContactsFill style={{ marginLeft: '-4px' }} />}
        text="Melden Sie sich ganz unverbindlich bei mir und wir kommen ins Gespräch"
        onClick={showContactModal}
      />
      <Button
        label={<>Kostenloses<br/>Kennenlerngespräch</>}
        icon={<FaPhoneAlt style={{ marginLeft: '-2px' }} />}
        text={`Ich biete für jeden ein unverbindliches und kostenloses Kennenlerngespräch an`}
        onClick={showContactModal}
      />
      <Button
        label="Zu Ihrem Coach"
        icon={<IoIosHome style={{ marginLeft: '-1px' }} />}
        text="Erfahren Sie mehr über mich"
        href="/informationen/ihr-coach"
      />
    </Card>
  )
}

export default ContactCard
