import { FC, useMemo } from 'react'
import Link from 'next/link'
import classnames from 'classnames'
import Styles from './NavigationContent.module.sass'

interface NavigationContentProps {
  drawer?: boolean,
}

const NavigationContent: FC<NavigationContentProps> = ({
  drawer = false,
}) => {
  const mainListClassNames = useMemo(() => classnames({
    [Styles.mainUl]: true,
    [Styles.mainUlHeader]: !drawer,
    [Styles.mainUlDrawer]: drawer,
  }), [drawer])

  const dropdownClassNames = useMemo(() => classnames({
    [Styles.dd]: true,
    [Styles.ddHeader]: !drawer,
    [Styles.ddDrawer]: drawer,
  }), [drawer])

  return (
    <>
      <ul className={mainListClassNames}>
        <li>
          <Link href="/angebot-privatpersonen">
            <a>Angebote Privatpersonen</a>
          </Link>
        </li>
        <li>
          <Link href="/angebot-firmen">
            <a>Angebote Firmen</a>
          </Link>
        </li>
        <li>
          <Link href="/seminare-workshops">
            <a>Seminare &amp; Workshops</a>
          </Link>
        </li>
        <li>
          <Link href="/service">
            <a>Service</a>
          </Link>
        </li>
      </ul>
      <div className={dropdownClassNames}>
        {!drawer && (
          <button className={Styles.moreButton}>
            Mehr&nbsp;
            <i className="fc-icon fc-icon-small fc-icon-plus fc-icon-dark-brown" />
          </button>
        )}
        <ul>
          <li className="separator">Angebote Privatpersonen</li>
          <li>
            <Link href="/angebot-privatpersonen/angebote-fuer-senioren">
              <a>Angebote Für Senioren</a>
            </Link>
          </li>
          <li>
            <Link href="/angebot-privatpersonen/beratung-und-therapie-hpg-berlin-mitte">
              <a>Beratung und Therapie (HPG)</a>
            </Link>
          </li>
          <li className="separator">Informationen</li>
          <li>
            <Link href="/informationen/ihr-coach">
              <a>Ihr Coach</a>
            </Link>
          </li>
          <li>
            <Link href="/blog">
              <a>Blog</a>
            </Link>
          </li>
          <li>
            <Link href="/informationen/fragen-und-antworten">
              <a>Fragen und Antworten</a>
            </Link>
          </li>
          <li className="separator">Sonstiges</li>
          <li>
            <Link href="/sonstiges/impressum">
              <a>Impressum</a>
            </Link>
          </li>
          <li>
            <Link href="/sonstiges/datenschutzerklaerung">
              <a>Datenschutzerklärung</a>
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}

export default NavigationContent
